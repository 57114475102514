import auth from "../middleware/auth";

const routes = [
  {
    path: "/transactions",
    name: "Transaction",
    component: () => import("../../views/protected/transactions"),
    meta: {
      layout: () => import("../../layouts/authenticated"),
      middleware: [auth],
    },
  },
];

export default routes;
