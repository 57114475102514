<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total logs
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ pagination.total }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar class="primary--text text-h6" flat> All Logs </v-toolbar>
          <search-and-filter :placeholder="placeholder"></search-and-filter>
          <v-data-table
            class="row-pointer"
            hide-default-footer
            :headers="headers"
            :items="logs"
            :loading="loadLogs"
            :items-per-page="pagination.limit"
            @click:row="showDetails"
          >
            <template v-slot:item.numbers="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.payload.request="{ item }">
              {{ JSON.stringify(item.payload.request) }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ helpers.formatDate(item.createdAt) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div
      v-if="pagination.total > pagination.limit"
      class="my-4 d-flex justify-end"
    >
      <v-pagination
        v-model="pagination.page"
        :length="Math.ceil(pagination.total / pagination.limit)"
        :total-visible="pagination.total < 3 ? 2 : 6"
        @input="getNext"
      ></v-pagination>
    </div>
    <log-details :log="log" ref="logDetails"></log-details>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Helpers from "../../utils/helpers";
import searchAndFilter from "../../components/searchAndFilter.vue";
import LogDetails from "@/components/logs/LogDetails.vue";
export default {
  name: "LogPage",
  components: {
    searchAndFilter,
    LogDetails,
  },
  data() {
    return {
      helpers: new Helpers(),
      loading: false,
      loadLogs: false,
      headers: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Type", value: "type" },
        { text: "Module", value: "module" },
        { text: "Request", value: "payload.request" },
        { text: "Date", value: "createdAt" },
      ],
      logs: [],
      pagination: {
        limit: 10,
        page: 1,
        total: 0,
      },
      totalLogs: 0,
      placeholder: "search... type, request, response, module",
      log: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      get_logs: "logs/get_logs",
    }),
    async getLogs(filter = null, filtered = null) {
      let data = filter;
      if (!filtered) {
        data = `?page=${this.pagination.page}&limit=${this.pagination.limit}`;
        this.$root.$emit("filtered", false);
      } else {
        this.$root.$emit("filtered", true);
      }

      this.loadLogs = true;
      try {
        const response = await this.get_logs(data);
        this.logs = response.data.logs;
        this.pagination.total = response.data.itemCount;
        this.pagination.page = response.data.currentPage;
        this.loadLogs = false;
      } catch (e) {
        this.loadLogs = false;
      }
    },
    async nextPage(filter, filtered) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      await this.getLogs(filter, filtered);
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
    },
    showDetails(row) {
      this.log = { ...row };
      this.$refs.logDetails.openDialog();
    },
    getNext() {
      this.$root.$emit(`paginate`, this.pagination);
    },
  },
  created() {
    this.getLogs();
  },
  mounted() {
    this.$root.$on("filter-logs", (data, filtered) => {
      const { pagination } = data;
      this.pagination = { ...pagination };
      const filter = `?page=${pagination.page}&limit=${pagination.limit}&search=${data.search}&start_date=${data.start_date}&end_date=${data.end_date}`;
      this.nextPage(filter, filtered);
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate");
    this.$root.$off("filtered");
  },
};
</script>
