<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="pa-4 text-left">
        <div>
          <strong>Type: </strong>
          <span>{{ log.type }}</span>
        </div>
        <div v-if="log.module">
          <strong>Module: </strong>
          <span>{{ log.module }}</span>
        </div>
        <div v-if="log.payload">
          <strong>Request: </strong>
          <span>
            <pre>{{ log.payload.request }}</pre>
          </span>
        </div>
        <div v-if="log.payload">
          <strong>Response: </strong>
          <span>
            <pre>{{ log.payload.response }}</pre>
          </span>
        </div>
        <div>
          <strong>Date: </strong>
          <span>
            {{ helpers.formatDate(log.createdAt) }}
          </span>
        </div>
        <v-card-actions
          style="
            position: sticky;
            bottom: 0;
            opacity: 1;
            background-color: #ffffff;
          "
        >
          <v-spacer></v-spacer>
          <v-btn depressed class="close mr-3" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Helpers from "@/utils/helpers";
export default {
  props: {
    log: {
      default: Object,
      type: Object,
    },
  },
  name: "LogDetails",
  data() {
    return {
      dialog: false,
      helpers: new Helpers(),
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  justify-content: flex-end;
}
</style>
